import { createSlice } from '@reduxjs/toolkit';
import {
  getThemeListLongFormat,
  clusterService,
  getVideoLevelThemeListLongFormat,
} from '../asyncActions/longFormatActions';
import { toast } from 'react-toastify';

export type themeType = {
  id: number;
  name: string;
};

interface longFormatType {
  loading: boolean;
  longFormatThemes: themeType[];
  jobStatus: string;
  clusteringPending: boolean;
  haveExistingCluster: boolean;
  videoLevelThemesList: Array<any>;
  notesOpenPanelId: string;
  notesStarId: string;
  playgroundResponse: Array<any>;
  playgroundUsersList: Array<number>;
  contentAnalysisSummaryStatus: boolean;
}
const initialState: longFormatType = {
  loading: false,
  longFormatThemes: [],
  jobStatus: '',
  clusteringPending: false,
  haveExistingCluster: false,
  videoLevelThemesList: [],
  notesOpenPanelId: '',
  notesStarId: '',
  playgroundResponse: [],
  playgroundUsersList: [],
  contentAnalysisSummaryStatus: false,
};

export const LongFormatSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setContentAnalysisSummaryStatus: (state, action) => {
      state.contentAnalysisSummaryStatus = action.payload;
    },
    setNotesStarId: (state, action) => {
      state.notesStarId = action.payload;
    },
    setPlaygroundResponse: (state, action) => {
      state.playgroundResponse = action.payload;
    },
    setPlaygroundUsers: (state, action) => {
      state.playgroundUsersList = action.payload;
    },
    openNotePanel: (state, action) => {
      state.notesOpenPanelId = action.payload;
    },
    updateJobStatus: (state, action) => {
      state.jobStatus = action.payload;
    },
    updateClusteringPending: (state, action) => {
      state.clusteringPending = action.payload;
    },
    updateHaveExistingCluster: (state, action) => {
      state.haveExistingCluster = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThemeListLongFormat.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThemeListLongFormat.fulfilled, (state, action) => {
        state.longFormatThemes = action.payload.data.data;
        state.jobStatus = action.payload.data.jobStatus;
        state.loading = false;
      })
      .addCase(getThemeListLongFormat.rejected, (state, payload) => {
        state.loading = false;
      })
      .addCase(getVideoLevelThemeListLongFormat.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVideoLevelThemeListLongFormat.fulfilled, (state, action) => {
        state.videoLevelThemesList = action.payload.data.value;
        state.loading = false;
      })
      .addCase(getVideoLevelThemeListLongFormat.rejected, (state, payload) => {
        state.loading = false;
      })
      .addCase(clusterService.fulfilled, (state) => {
        state.jobStatus = 'pending';
        toast.success('Theme Generation Successfull');
      })
      .addCase(clusterService.rejected, (state) => {
        toast.error('Themes Failed to Generate');
      });
  },
});

export const { actions: LongFormatActions } = LongFormatSlice;
export default LongFormatSlice.reducer;
