import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiServiceInstanceDashboard } from '../apiUtils';
import { ReelDetails } from '../Reducers/aiAnalysisReducer';

export const reelsGetList = createAsyncThunk(
  'reelsGetList',
  async (payload: {
    batchId: number | string;
    questionId: number | string;
  }) => {
    const res = await apiServiceInstanceDashboard.post(
      '/reel/getlist/',
      payload,
    );

    return res.data?.value || [];
  },
);

export const reelsDetails = createAsyncThunk(
  'reelsGet',
  async (payload: {
    reelId: number | string;
    batchId: number | string;
    questionId: number | string;
    questionType: string;
  }) => {
    const res = await apiServiceInstanceDashboard.post('/reel/get/', payload);

    return { reelId: payload.reelId, value: res.data?.value } || [];
  },
);

export const updateReel = createAsyncThunk(
  'updateReel',
  async (payload: ReelDetails) => {
    await apiServiceInstanceDashboard.put('/reel/main/', payload);

    return payload;
  },
);

export const createReel = async (payload: {
  title: string;
  batchId: number | string;
  questionId: number | string;
}) => {
  const res = await apiServiceInstanceDashboard.post('/reel/main/', payload);
  if (res.data.status === 'success') {
    return res.data.id;
  }
  return null;
};

export const createClip = async (payload: {
  clipTitle: string;
  reelTitle?: string;
  reelId?: number;
  endTime: number;
  startTime: number;
  videoId: number;
  batchId: number | string;
  questionId: number | string;
}) => {
  const res = await apiServiceInstanceDashboard.post('/reel/info/', payload);
  if (res.data.status === 'success') {
    return res.data.value;
  }
  return {};
};

export const deleteReelInfo = async (payload: {
  reelId?: number;
  batchId: number;
  reelInfoId: number;
}) => {
  const res = await apiServiceInstanceDashboard.delete('/reel/info/', {
    data: payload,
  });
  return res;
};

export const reelChangeOrder = async (payload: {
  orderId: number;
  reelId: number;
  reelInfoId: number;
  batchId: number;
}) => {
  const res = await apiServiceInstanceDashboard.post(
    '/reel/changeorder/',
    payload,
  );
  return res;
};
export const getLongFormatSingleReel = async (payload: {
  batchId: number;
  questionId: number;
  videoId: number;
}) => {
  const res = await apiServiceInstanceDashboard.post(
    '/reel/getForSingleVideo/',
    payload,
  );
  return res;
};
export const checkJobStatus = createAsyncThunk(
  'checkJobStatus',
  async (payload: any) => {
    const res = await apiServiceInstanceDashboard.post(
      '/aianlysis/check_status/',
      payload,
    );

    return res.data?.values || [];
  },
);

export type GetUserThemesQuery = {
  batchId: number;
  questionId: number;
  groupId: number;
};

export const getUserThemes = createAsyncThunk(
  'getUserThemes',
  async (query: GetUserThemesQuery) => {
    const resonse = await apiServiceInstanceDashboard.get('/aianlysis/user_themes/', {
      params: query,
    });

    return resonse.data?.values || [];
  },
);

export type UserThemePayload = {
  batchId: number;
  questionId: number;
  theme: string;
  groupId: number;
  description: string;
  isActive: boolean;
  userThemeId?: number;
  showToast?: boolean;
  example: string | null;
};

export const addUserTheme = createAsyncThunk(
  'addUserTheme',
  async (payload: UserThemePayload) => {
    await apiServiceInstanceDashboard.post('/aianlysis/user_themes/', payload);
    return payload;
  },
);

export const updateUserTheme = createAsyncThunk(
  'updateUserTheme',
  async (payload: UserThemePayload) => {
    const resonse = await apiServiceInstanceDashboard.put('/aianlysis/user_themes/', payload);
    return resonse.data?.values || [];
  },
);

export const deleteUserTheme = createAsyncThunk(
  'deleteUserTheme',
  async (payload: {userThemeId: number, batchId: number, questionId: number}) => {
    const resonse = await apiServiceInstanceDashboard.delete('/aianlysis/user_themes/', {
      data: payload,
    });

    return resonse.data?.values || [];
  },
);

export const createUserThemeGroup = createAsyncThunk(
  'createUserThemeGroup',
  async (payload: { batchId: number; questionId: number; themeType: string; }) => {
    const res = await apiServiceInstanceDashboard.post(
      '/aianlysis/user_theme_group/',
      payload,
    );
    return res.data?.values;
  },
);

export const updateUserThemeGroup = createAsyncThunk(
  'updateUserThemeGroup',
  async (payload: { batchId: number; questionId: number; groupId: number; tagId: number; }) => {
    const res = await apiServiceInstanceDashboard.put(
      '/aianlysis/user_theme_group/',
      payload,
    );
    return payload;
  },
);

export const createUserPromptInstruct = createAsyncThunk(
  'createUserPromptInstruct',
  async (payload: { batchId: number; questionId: number; }) => {
    const res = await apiServiceInstanceDashboard.post(
      '/aianlysis/user_prompt_instruct/',
      payload,
    );
    return res.data?.values;
  },
)

export const updateUserPromptInstruct = createAsyncThunk(
  'updateUserPromptInstruct',
  async (payload: { batchId: number; questionId: number; userInstructId: number; tagId: number }) => {
    const res = await apiServiceInstanceDashboard.put(
      '/aianlysis/user_prompt_instruct/',
      payload,
    );
    return payload;
  },
)
export const getUserPromptInstruct = createAsyncThunk(
  'getUserPromptInstruct',
  async (payload: { userInstructId: number }) => {
    const res = await apiServiceInstanceDashboard.get(
      '/aianlysis/user_prompt_instruct/',
      { params: payload },
    );
    return res.data?.values;
  },
)

export const triggerSuggestThemes = createAsyncThunk(
  'triggerSuggestThemes',
  async (payload: { batchId: number; questionId: number; deleteExistingThemes: boolean; prompt: string;}) => {
    const res = await apiServiceInstanceDashboard.post(
      '/aianlysis/trigger_suggest_themes/',
      payload,
    );
    return res.data?.values;
  },
)

export const triggerNER = createAsyncThunk(
  'triggerNER',
  async (payload: { batchId: number; questionId: number;}) => {
    const res = await apiServiceInstanceDashboard.post(
      '/aianlysis/trigger_ner/',
      payload,
    );
    return res.data?.values;
  },
)


export type GetThemeTagPayload = {
  batchId: number;
  questionId: number;
};

export const getThemeTags = async (query: GetThemeTagPayload) => {
  const resonse = await apiServiceInstanceDashboard.get('/aianlysis/theme_tags/', {
    params: query,
  });

  return resonse.data?.values || [];
}

export const createThemeTag = createAsyncThunk(
  'createThemeTag',
  async (payload: {
    batchId: number;
    questionId: number;
    title: string;
    color: string;
  }) => {
    const res = await apiServiceInstanceDashboard.post('/aianlysis/theme_tags/', payload);
    return res.data?.values;
  },
)

export const updateThemeTag = createAsyncThunk(
  'updateThemeTag',
  async (payload: {
    batchId: number;
    questionId: number;
    title?: string;
    color?: string;
    tagId: number;
  }) => {
    const res = await apiServiceInstanceDashboard.put('/aianlysis/theme_tags/', payload);
    return res.data?.values;
  },
)

export const getThemeOperationStatus = createAsyncThunk(
  'getThemeOperationStatus',
  async (payload: { batchId: number; questionId: number; }) => {
    const res = await apiServiceInstanceDashboard.post('/aianlysis/theme_operation_status/', payload);
    return res.data || [];
  },
)

export const deleteThemeTag = createAsyncThunk(
  'deleteThemeTag',
  async (payload: {
    tagId: number;
  }) => {
    const res = await apiServiceInstanceDashboard.delete('/aianlysis/theme_tags/', {
      data: payload,
    });
    return res.data?.values;
  },
)

export const getReviewUserThemes = createAsyncThunk(
  'getReviewUserThemes',
  async (payload: { batchId: number; questionId: number; }) => {
    const res = await apiServiceInstanceDashboard.post('/aianlysis/review_user_themes/', payload);
    return res.data?.values;
  },
);
