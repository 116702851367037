import { getFilenameFromUrl } from "./string";

export function sanitizeFileName(file: File) {
  // file name will only contain alphanumeric & _
  const filenameChunks = file.name.split('.');
  const extension = filenameChunks.pop();
  const filename = filenameChunks
    .splice(0, filenameChunks.length)
    .join()
    .replace(/[^A-Z0-9]+/gi, '_');

  return new File([file], `${filename}.${extension}`, {
    type: file.type,
    lastModified: file.lastModified,
  });
}

export const downloadAs = (url: string, filename: string | null = null) =>
  fetch(url, {
    method: 'GET',
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute(
        'download',
        filename || (getFilenameFromUrl(url) as string),
      );

      // Append to html link element page
      document.body.appendChild(link);

      link.click();
      link.parentNode?.removeChild(link);
      return filename;
    });

export function formatBytes(bytes: any, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function arrayToCSV(data: any, delimiter = ',', headers: any) {
  if (!Array.isArray(data) || data.length === 0) {
    return '';
  }

  const csvRows = data.map((row) => {
    return headers
      .map((header: any) => {
        const cell =
          row[header.key] === null || row[header.key] === undefined
            ? ''
            : row[header.key];
        // Escape double quotes in values by doubling them
        return `"${String(cell).replace(/"/g, '""')}"`;
      })
      .join(delimiter);
  });

  // Combine headers and rows into a single CSV string
  return [headers.map((head: any) => head.title), ...csvRows].join('\n');
}
