import Cookies from 'js-cookie';

export * from './file';
export * from './string';
export * from './groupObjects';

export const getToken = () => {
  let token = '';
  const a = Cookies.get();
  if (a?.loginObj) {
    const loginObject = JSON.parse(a.loginObj);
    token = loginObject?.data?.auth_token || '';
  }
  return token;
};

export const formatMb = (num: number) => {
  if (num < 1) {
    return `${num * 1000} KBps`;
  }

  if (num > 1024) {
    return `${num / 1024} GBps`;
  }
  return `${num} MBps`;
};
