import { RootState } from "@/store/index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useProfileInfo = () => {
const loggedInUserObj = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserObj,
  );
  const [userColor, setUserColor] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [profilePicUrl, setProfilePicUrl] = useState('');
  const [userId, setUserId] = useState(-1);

  const colorList = [
    '#8bbeec',
    '#ffbb94',
    '#89ca82',
    '#ff8d8a',
    '#f2b5d5',
    '#ffb766',
    '#aecaf4',
    '#bbb4ff',
    '#b8cb63',
    '#e9e196',
  ];

  useEffect(() => {
    if (loggedInUserObj && loggedInUserObj.user_name !== '') {
      setUsername(loggedInUserObj.user_name);
      setUserColor(colorList[loggedInUserObj.user_name.length % colorList.length]);
    }
    if (loggedInUserObj && loggedInUserObj.email !== '') {
      setEmail(loggedInUserObj.email);
    }
    if (loggedInUserObj && !!loggedInUserObj.profile_pic_url) {
      setProfilePicUrl(loggedInUserObj.profile_pic_url);
    } 
    if (loggedInUserObj && loggedInUserObj?.user_id !== 0) {
      setUserId(loggedInUserObj?.user_id);
    }
  }, [loggedInUserObj]);

  return {
    userId,
    email,
    userColor,
    username,
    profilePicUrl,
  }
}

export default useProfileInfo;
