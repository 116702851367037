import { createSlice } from '@reduxjs/toolkit';
import {
  createNewReport,
  reportsGetElements,
  reportsGetList,
  reportsGetPageList,
  setLayout,
} from '../asyncActions/reportActions';

interface reportStateType {
  isLoading: boolean;
  drawerReportId: string | number | null;
  copiedElementData: any;
  reportsList: Array<any>;
  reportsPageList: Array<any>;
  reportPageId: number;
  reportElements: object;
}

const initialState: reportStateType = {
  isLoading: false,
  copiedElementData: null,
  reportsList: [],
  reportsPageList: [],
  drawerReportId: null,
  reportPageId: 0,
  reportElements: {},
};

export const ReportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    copyElement: (state, action) => {
      state.copiedElementData = action.payload;
    },
    clearCopiedElement: (state) => {
      state.copiedElementData = null;
    },
    selectDrawerReport: (state, action) => {
      state.drawerReportId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reportsGetList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reportsGetList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reportsList = action.payload;
      })
      .addCase(reportsGetList.rejected, (state) => {
        state.isLoading = false;
      })
      // .addCase(reportsGetElements.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(reportsGetElements.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.reportElements = action.payload;
      // })
      // .addCase(reportsGetElements.rejected, (state) => {
      //   state.isLoading = false;
      // })
      .addCase(reportsGetPageList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reportsGetPageList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reportsPageList = action.payload;
      })
      .addCase(reportsGetPageList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createNewReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.drawerReportId = parseInt(action.payload.id, 10);
      })
      .addCase(createNewReport.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { actions: reportActions } = ReportsSlice;
export default ReportsSlice.reducer;
