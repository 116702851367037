import Head from 'next/head';
import type { AppProps } from 'next/app';
import type { NextComponentType } from 'next';
import { ConfigProvider, Spin } from 'antd';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { store } from '../store';
import { AuthProvider } from '../context/auth';
import { AuthGuard } from '../components/Layouts/AuthGuard';
import ToastLayout from '../components/Layouts/ToastLayout';
import Loader from '../components/Common/Loader';
import { hotjar } from 'react-hotjar';
import '../styles/globals.css';
import 'antd/dist/antd.css';

import { useRouter } from 'next/router';
import PagesLayout from '@/components/Layouts/PagesLayout';
import zipy from 'zipyai'; 

Spin.setDefaultIndicator(<Loader />);

if (
  process.env.NEXT_PUBLIC_LOCATION === 'USA' &&
  typeof window !== 'undefined'
) {
  hotjar.initialize(3179575, 6);
  ReactGA.initialize('G-Z9CQXMB3KN');
  zipy.init('ea1bf54d')
}
else if(process.env.NEXT_PUBLIC_LOCATION === 'EUROPE' &&
typeof window !== 'undefined'
) {
  hotjar.initialize(5070333, 6);
  ReactGA.initialize('G-Z9CQXMB3KN');
  zipy.init('f1455a43')
}

type CustomAppProps = AppProps & {
  Component: NextComponentType & {
    accessType: 'authenticated' | 'anonymous' | null;
  };
};

ConfigProvider.config({
  theme: { primaryColor: '#5579F2' },
});

function MyApp({ Component, pageProps }: CustomAppProps) {
  const { accessType = null } = Component;
  const router = useRouter();
  return (
    <>
      <Head>
        <title>Enumerate AI</title>
      </Head>
      <Provider store={store}>
        <AuthProvider>
          <ToastLayout>
            <ConfigProvider>
              <AuthGuard accessType={accessType}>
                {router.pathname.startsWith('/setting') ||
                router.pathname.startsWith('/billing') ? (
                  <PagesLayout>
                    <Component {...pageProps} />
                  </PagesLayout>
                ) : (
                  <Component {...pageProps} />
                )}
              </AuthGuard>
            </ConfigProvider>
          </ToastLayout>
        </AuthProvider>
      </Provider>
    </>
  );
}

export default MyApp;
