import { createSlice } from '@reduxjs/toolkit';
import {
  addSurvey,
  deleteProject,
  deleteSurvey,
  duplicateSurvey,
  getAllProjects,
  getAllSurveys,
  getPermissions,
  getSearchSurveys,
  getStudyPermissions,
  getClientUsage,
  getMyUsage,
} from '../asyncActions/questionBuilder';
import { HomeState } from './Types';
import { toast } from 'react-toastify';
import {
  getTemplateCategories,
  getTemplateQuestions,
  getTemplates,
} from '../asyncActions';

export const initialState: HomeState = {
  loading: false,
  currentProjectId: -1,
  projects: [],
  surveys: [],
  count: 0,
  search: {
    batchList: [],
    projectList: [],
    status: '',
  },
  isDelete: false,
  toastQueue: [],
  redirectTo: null,
  templateCategories: [],
  templates: [],
  currentTemplateQuestions: [],
  studyPermissions: {},
  clientData: {},
  myUsageData: {},
  questionBuilderPermissions: [],
  permissions: {},
};

export const HomeSlice = createSlice({
  name: 'Home Responses',
  initialState,
  reducers: {
    clearSearch: (state) => {
      state.search = initialState.search;
    },
    emptyToastQueue: (state) => {
      state.toastQueue = [];
    },
    addToast: (state, action) => {
      state.toastQueue?.push(action.payload);
    },
    resetRedirectTo: (state) => {
      state.redirectTo = null;
    },
    setCurrentProject: (state, action) => {
      state.currentProjectId = action.payload;
    },
    setSurveyEmpty: (state) => {
      state.surveys = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissions.pending, (state) => {
        state.loading = true;
        state.permissions = initialState.permissions;
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload;
      })
      .addCase(getPermissions.rejected, (state, payload) => {
        state.permissions = initialState.permissions;
      })
      .addCase(getMyUsage.pending, (state) => {
        state.loading = true;
        state.myUsageData = initialState.myUsageData;
      })
      .addCase(getMyUsage.fulfilled, (state, action) => {
        state.loading = false;
        state.myUsageData = action.payload.data;
      })
      .addCase(getMyUsage.rejected, (state, payload) => {
        state.myUsageData = initialState.myUsageData;
      })
      .addCase(getClientUsage.pending, (state) => {
        state.loading = true;
        state.clientData = initialState.clientData;
      })
      .addCase(getClientUsage.fulfilled, (state, action) => {
        state.loading = false;
        state.clientData = action.payload.data;
      })
      .addCase(getClientUsage.rejected, (state, payload) => {
        state.clientData = initialState.clientData;
      })
      .addCase(getStudyPermissions.pending, (state) => {
        state.loading = true;
        state.studyPermissions = initialState.studyPermissions;
        state.questionBuilderPermissions =
          initialState.questionBuilderPermissions;
      })
      .addCase(getStudyPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.studyPermissions = action.payload.data.value;
        state.questionBuilderPermissions = action.payload.data.questionBuilder;
      })
      .addCase(getStudyPermissions.rejected, (state, payload) => {
        state.studyPermissions = initialState.studyPermissions;
        state.questionBuilderPermissions =
          initialState.questionBuilderPermissions;
      })
      .addCase(getAllProjects.pending, (state) => {
        state.loading = true;
        state.projects = initialState.projects;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.isDelete = false;
        state.projects = action.payload.data.value;
      })
      .addCase(getAllProjects.rejected, (state, payload) => {
        state.projects = initialState.projects;
      })
      .addCase(deleteProject.pending, (state) => {
        state.loading = true;
        state.isDelete = false;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.loading = false;
        state.isDelete = true;
      })
      .addCase(deleteProject.rejected, (state, payload) => {
        state.loading = false;
        toast.error(payload.error.message, {
          autoClose: 1500,
        });
        state.isDelete = false;
      })
      .addCase(getAllSurveys.pending, (state) => {
        state.loading = true;
        state.surveys = initialState.surveys;
      })
      .addCase(getAllSurveys.fulfilled, (state, action) => {
        state.loading = false;
        state.surveys = action.payload.data.value;
        state.count = action.payload.data.count;
      })
      .addCase(getAllSurveys.rejected, (state, payload) => {
        state.surveys = initialState.surveys;
      })
      .addCase(getSearchSurveys.pending, (state) => {
        state.search = initialState.search;
      })
      .addCase(getSearchSurveys.fulfilled, (state, action) => {
        state.search = action.payload.data;
      })
      .addCase(getSearchSurveys.rejected, (state, payload) => {
        state.search = initialState.search;
      })
      .addCase(deleteSurvey.rejected, (state) => {
        state.toastQueue?.push({
          level: 'error',
          message: 'Something Went Wrong',
        });
      })
      .addCase(deleteSurvey.fulfilled, (state) => {
        // state.toastQueue?.push({
        //   level: 'success',
        //   message: 'Successfully Deleted',
        // });
      })
      .addCase(addSurvey.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSurvey.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addSurvey.fulfilled, (state, action) => {
        state.loading = false;
        const createdSurveyTitle = action.payload.title;
        const researchType = action.payload.researchType;
        const researchSubType = action.payload.researchSubType;

        const currentSurvey = state.surveys?.find(
          (d) => d.title === createdSurveyTitle,
        );
        if (currentSurvey) {
          if (researchType === 'long_format') {
            state.redirectTo = `/longformat?tabId=upload&id=${currentSurvey.id}`;
          } else if (researchType === 'Dashboard') {
            state.redirectTo = `/templates?tabId=report&id=${currentSurvey.id}`;
          } else {
            if (
              researchSubType === 'survey' ||
              researchSubType === 'video_diary' ||
              researchSubType === 'product_testing'
            )
              state.redirectTo = `/survey?tabId=edit&id=${currentSurvey.id}`;
            else if (researchSubType === 'excel')
              state.redirectTo = `/dashboard/?tabId=upload&id=${currentSurvey.id}`;
            else {
            }
          }

          state.toastQueue?.push({
            level: 'success',
            message: 'Study Created Successfully !',
          });
        }
      })
      .addCase(duplicateSurvey.pending, (state) => {
        state.loading = true;
      })
      .addCase(duplicateSurvey.rejected, (state) => {
        state.loading = false;
        state.toastQueue?.push({
          level: 'error',
          message: 'Something Went Wrong',
        });
      })
      .addCase(duplicateSurvey.fulfilled, (state) => {
        state.loading = false;
        state.toastQueue?.push({
          level: 'success',
          message: 'Successfully Copied !',
        });
      })
      .addCase(getTemplateCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTemplateCategories.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTemplateCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.templateCategories = action.payload.value;
      })
      .addCase(getTemplates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTemplates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.templates = action.payload.value;
      })
      .addCase(getTemplateQuestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTemplateQuestions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTemplateQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.currentTemplateQuestions = action.payload.value;
      });
  },
});

export const { actions: homeActions } = HomeSlice;
export default HomeSlice.reducer;
