import ColabSvg from '@/assets/colab.svg';
import { EnumerateLogo } from "@/components/Dashboard/Icons";
import useCobranding from "@/hooks/useCobranding";
import { Popover } from "antd";
import Image from "next/legacy/image";
import { useRouter } from 'next/router';
import { useEffect, useState } from "react";

const Colab = () => {
  const {pathname} = useRouter();

  const { isCobranding, brandLogo } = useCobranding();
  const [isPopShown, setIsPopShown] = useState(false);

  useEffect(() => {
    const localVar = `isPopShown${pathname.replace('/', '')}`
    const popShownInStorage = localStorage.getItem(localVar);
    if (popShownInStorage === null || popShownInStorage === 'false') {
      setIsPopShown(true);
      setTimeout(() => setIsPopShown(false), 3000);
      localStorage.setItem(localVar, 'true');
    }
  }, [pathname]);

  const content = (
    <div className='flex space-x-2'>
      <EnumerateLogo withName={isCobranding} />
      {brandLogo && <>
        <div className='w-0.5 h-10 bg-enum-border-color' />
        <img src={brandLogo} className='h-10 w-38 object-contain' />
      </>}
    </div>
  );

  return (
    <Popover content={content} placement="right" trigger="hover" open={isPopShown} onOpenChange={setIsPopShown}>
      <Image src={ColabSvg.src} height={40} width={40} />
    </Popover>
  )
}

export default Colab;