import { AppDispatch, RootState } from "@/store/index";
import { HomeSlice } from "@/store/Reducers/homeReducer";
import React, { useEffect } from "react"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  children: JSX.Element 
}

const ToastLayout = ({children} : Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: RootState) => state.homeReducer.loading);
  const toastQueue = useSelector(
    (state: RootState) => state.homeReducer.toastQueue,
  );

  useEffect(() => {
    if (!loading && !!toastQueue?.length) {
      toastQueue.forEach((t) => toast[t.level](t.message, t.config || {}));
      dispatch(HomeSlice.actions.emptyToastQueue());
    }
  }, [toastQueue, loading]);

  return (
    <>
      <ToastContainer position="top-right"/>
      {children}
    </>
  )
}


export default ToastLayout;
