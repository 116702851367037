import { configureStore } from '@reduxjs/toolkit';
import surveyReducer from './Reducers/surveyReducer';
import loginReducer from './Reducers/loginReducer';
import homeReducer from './Reducers/homeReducer';
import aiAnalysisReducer from './Reducers/aiAnalysisReducer';
import dashboardReducer from './Reducers/dashboardReducer';
import longFormatReducer from './Reducers/longFormatReducer';
import templateReducer from './Reducers/templateReducer';
import {
  useDispatch as useDispatchBase,
  useSelector as useSelectorBase,
} from 'react-redux';
import reportReducer from './Reducers/reportReducer';

/**
 * Creates a store and includes all the slices as reducers.
 */
export const store = configureStore({
  reducer: {
    loginReducer,
    homeReducer,
    surveyReducer,
    dashboardReducer,
    aiAnalysisReducer,
    reportReducer,
    longFormatReducer,
    templateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: { users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Since we use typescript, lets utilize `useDispatch`
export const useDispatch = () => useDispatchBase<AppDispatch>();

// And utilize `useSelector`
export const useSelector = <TSelected = unknown>(
  selector: (state: RootState) => TSelected,
): TSelected => useSelectorBase<RootState, TSelected>(selector);
