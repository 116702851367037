type substringSearchMatch = {
  value: string;
  index: number;
};

export function substringSearch(
  haystack: Array<string>,
  elem: string,
): Array<substringSearchMatch> {
  const pin = elem.toLocaleLowerCase().trim();

  return haystack.reduce(
    (acc: Array<substringSearchMatch>, t: string, idx: number) => {
      if (t.toLowerCase().trim().includes(pin)) {
        return [...acc, { value: t, index: idx }];
      }
      return acc;
    },
    [] as Array<substringSearchMatch>,
  );
}

export function isValidUrl(url: string) {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
}

export const highlightBoldText = (text: string) => {
  const boldRegex = /\*\*(.*?)\*\*/g;
  const highlightedText = text.replace(boldRegex, '<strong>$1</strong>');
  const headingRegex = /^(###.*)$/gm; // Updated regex pattern
  const formattedText = highlightedText.replace(
    headingRegex,
    '<span class="text-2xl">$1</span>',
  );
  const formattedText1 = formattedText.replace(/###/g, ''); // Replace with empty string
  return formattedText1;
};

export const extractInnerTextFromHTML = (htmlString: string) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;
  return tempElement.innerText;
};

export const camelCaseToCapitalized = (str: string) => {
  let words: any = str.replace(/([A-Z])/g, ' $1');
  words = words
    .split(' ')
    .map(
      (word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    );
  return words.join(' ');
};


export const getFilenameFromUrl = (url: string) => {
  if (!url) return '';

  // return url

  const urlParts = url.split('/');

  // also remove the query string
  const filename = urlParts[urlParts.length - 1].split('?')[0] || '';

  return filename;
}