import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiServiceInstanceDashboard } from '../apiUtils';

export const templatesGetList = createAsyncThunk(
  'templatesGetList',
  async (payload: { batchId: number | string }) => {
    const res = await apiServiceInstanceDashboard.post(
      'projectDahboard/getList/',
      payload,
    );
    return res.data?.value || [];
  },
);

export const templatesGetMetricList = createAsyncThunk(
  'templatesGetMetricList',
  async (payload: { batchId: number | string; dashboardId: number }) => {
    const res = await apiServiceInstanceDashboard.post(
      'projectDahboard/getMetricList/',
      payload,
    );
    return res.data?.value || [];
  },
);

export const templatesGetData = createAsyncThunk(
  'templatesGetData',
  async (payload: {
    batchId: number | string;
    dashboardId: number;
    dashboardMetricId: number;
  }) => {
    const res = await apiServiceInstanceDashboard.post(
      'projectDahboard/getData/',
      payload,
    );
    return res.data?.value || [];
  },
);
