import React from 'react';

import { Triangle } from 'react-loader-spinner';
const Loader = () => {
  return (
    <div className="w-full h-[80vh] flex justify-center items-center delay-1000">
      {/* <Triangle
        height="80"
        width="80"
        color="#d9d5fa"
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        visible={true}
      /> */}
      {/* <img
        src="https://d2zub0gh4qihh8.cloudfront.net/docs/loader-11.gif"
        style={{width:'90px', height:'60px' }}
      /> */}
       <div className="enumerate-loader">
            <div className="enumerate-square enumerate-vertical"></div>
            <div className="enumerate-square enumerate-horizontal"></div>
        </div>
    </div>
  );
};

export default Loader;
