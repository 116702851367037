import { createSlice } from '@reduxjs/toolkit';
import {
  templatesGetData,
  templatesGetList,
  templatesGetMetricList,
} from '../asyncActions/templateActions';

interface templateStateType {
  isLoading: boolean;
  templateList: Array<any>;
  metricList: Array<any>;
  data: any;
}

const initialState: templateStateType = {
  isLoading: false,
  templateList: [],
  metricList: [],
  data: null,
};

export const TemplateSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setTemplateListEmpty: (state: templateStateType, action) => {
      state.templateList = [];
    },
    setMetricListEmpty: (state: templateStateType, action) => {
      state.metricList = [];
    },
    setDataEmpty: (state: templateStateType, action) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(templatesGetList.rejected, (state, payload) => {
        state.isLoading = false;
        state = initialState;
      })
      .addCase(templatesGetList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(templatesGetList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.templateList = action.payload;
      })
      .addCase(templatesGetMetricList.rejected, (state, payload) => {
        state.isLoading = false;
        state = initialState;
      })
      .addCase(templatesGetMetricList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(templatesGetMetricList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.metricList = action.payload;
      })
      .addCase(templatesGetData.rejected, (state, payload) => {
        state.isLoading = false;
        state = initialState;
      })
      .addCase(templatesGetData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(templatesGetData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export const { setDataEmpty, setMetricListEmpty, setTemplateListEmpty } =
  TemplateSlice.actions;
export default TemplateSlice.reducer;
